import { sendHTTPRequest, CLAIM_ERRORS } from "../../util";

export function sendDocIQRequest(filename, docIQCallback) {
    console.log("Uploading receipt to DocIQ: ", filename);
    let docIQLink = "https://gander-receiptscanner.cognitiveservices.azure.com/documentintelligence/documentModels/prebuilt-receipt:analyze?api-version=2024-02-29-preview";
    let receiptLink = "https://ganderstorageaccount.blob.core.windows.net/receipts/" + filename;
    let docIQKey = "9KtCOxCQmHY85Gm6aRpieEWPewscbCokbyTnzZprXqeSDgs01kWIJQQJ99AKACYeBjFXJ3w3AAALACOG4Fkj";
    sendHTTPRequest({
        link: docIQLink,
        request: {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "Ocp-Apim-Subscription-Key": docIQKey,
            },
            body: JSON.stringify({urlSource: receiptLink})
        },
        decodeCallback: (response) => {
            return response;
        },
        responseCallback: async (response) => {
            console.log("DocIQ pre-response: ", response);
            let success = false;
            let attempts = 0;
            while (!success) {
                sendHTTPRequest({
                    link: response.headers.get("operation-location"),
                    request: {
                        method: "GET",
                        headers: {
                            "Ocp-Apim-Subscription-Key": docIQKey,
                        },
                    },
                    decodeCallback: (response) => {
                        return response.json();
                    },
                    responseCallback: (data) => {
                        if (data.status == "succeeded") {
                            docIQCallback(data);
                            success = true;
                        }
                        attempts++;
                        if (attempts > 10) {
                            success = true;
                        }
                    },
                    errorCallback: (error) => {
                        console.log("DocIQ Get Error: ", error);
                        success = true;
                    }
                });
                await new Promise(resolve => setTimeout(resolve, 1000));
            }
        },
        errorCallback: (error) => {
            console.log("DocIQ Upload Error: ", error);
        }
    });
}

// Hotel: ArrivalDate, DepartureDate, MerchantAddress, MerchantAliases, MerchantName, MerchantPhoneNumber, Items
// 
export function generateFailures(category, policy, flightDates, airportCodes, ocrFields, amount) {
    let claimErrors = [];
    if (category == "Transportation Reimbursement" || category == "Hotel Reimbursement" || category == "Meals Reimbursement") {
        console.log("Args: ", category, policy, flightDates, amount);
        console.log("OCR fields: ", ocrFields[0].fields);

        // Date check
        let validDate = false;
        let date = new Date(ocrFields[0].fields.ArrivalDate.valueDate.replace(/-/g, '/'));
        for (let i = 0; i < flightDates.length; i++) {
            if (date.toDateString() == flightDates[i].toDateString()) {
                validDate = true;
                break;
            }
        }
        if (!validDate) {
            claimErrors.push(CLAIM_ERRORS.DATE);
        }

        // Location check
        let origin = ocrFields[0].fields.MerchantAddress.content;
        sendHTTPRequest({
            link: "https://ganderapi-dev-aef7a8bmgkb2ath8.canadacentral-01.azurewebsites.net/getDistanceBetween",
            request: {
                method: "POST",
                headers: {},
                body: JSON.stringify({origin: origin, airports: airportCodes})
            },
            decodeCallback: (response) => {
                return response.json();
            },
            responseCallback: (data) => {
                let validLocation = false;
                for (let i = 0; i < data.distances.length; i++) {
                    if (data.distances[i] < 24000 /* 15 miles */) {
                        validLocation = true;
                        break;
                    }
                }
                if (!validLocation) {
                    claimErrors.push(CLAIM_ERRORS.LOCATION);
                }
            },
            errorCallback: (error) => {
                console.log("Distance error: ", error);
            }
        });

        // Alcohol check
        let items = ocrFields[0].fields.Items.valueArray.map((item) => item.valueObject.Description.content);
        console.log("Items: ", items);
        sendHTTPRequest({
            link: "https://ganderapi-dev-aef7a8bmgkb2ath8.canadacentral-01.azurewebsites.net/getCountAlcoholicDrinks",
            request: {
                method: "POST",
                headers: {},
                body: JSON.stringify({items: items})
            },
            decodeCallback: (response) => {
                return response.json();
            },
            responseCallback: (data) => {
                console.log("Alcohol data: ", data);
                if (data.count > items.length / 2) {
                    claimErrors.push(CLAIM_ERRORS.ALCOHOL); 
                }
            },
            errorCallback: (error) => {
                console.log("Alcohol error: ", error);
            }
        });

        // Total cost check

        // Policy check
        if ((category == "Transportation Reimbursement" && amount > policy.transportationReimbursement) ||
            (category == "Hotel Reimbursement" && amount > policy.hotelReimbursement) ||
            (category == "Meals Reimbursement" && amount > policy.mealReimbursement)) {
            claimErrors.push(CLAIM_ERRORS.COST);
        }
    }
    else if (category == "Delay Expenses" || category == "External Bag Damage" || category == "Item Damage" || category == "Lost Items") {
        /* NOT IMPLEMENTED */
    }
    return claimErrors;
}