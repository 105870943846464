// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../fonts/Outfit-VariableFont_wght.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("../fonts/SF-Pro.ttf", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Font imports */
@font-face {
    font-family: "Outfit";
    src: url(${___CSS_LOADER_URL_REPLACEMENT_0___}) format('truetype');
}
@font-face {
    font-family: "SF Pro";
    src: url(${___CSS_LOADER_URL_REPLACEMENT_1___}) format('truetype');
}
/**/

#Header_header_outer__LjGSe {
    width: 100%;
    height: 98px;
    background: white;
    filter: drop-shadow(0px 0px 1px rgba(0, 0, 0, .4));
}

#Header_airline_logo__UOTPx {
    position: absolute;
    width: 45px;
    height: 45px;
    left: 30px;
    top: 26px;
}

#Header_ganderAirways__zEsFZ {
    position: absolute;
    left: 90px;
    font-family: "Outfit", sans-serif;
    font-size: 36px;
    font-weight: 400;
    line-height: 40px;
    top: 29px;
    color: #2550E9;
}

#Header_return_div__kKGbO {
    position: absolute;
    width: 235px;
    height: 30px;
    right: 31px;
    top: 25px;
    border-radius: 8px;
    background-color: #3564E8;
    color: white;
    text-align: center;
    padding-top: 12px;
    font-size: 14px;
    font-family: "SF Pro", sans-serif;
    font-weight: 600;
    cursor: pointer;
}`, "",{"version":3,"sources":["webpack://./src/NotForm/Header.module.css"],"names":[],"mappings":"AAAA,iBAAiB;AACjB;IACI,qBAAqB;IACrB,+DAAoE;AACxE;AACA;IACI,qBAAqB;IACrB,+DAAkD;AACtD;AACA,GAAG;;AAEH;IACI,WAAW;IACX,YAAY;IACZ,iBAAiB;IACjB,kDAAkD;AACtD;;AAEA;IACI,kBAAkB;IAClB,WAAW;IACX,YAAY;IACZ,UAAU;IACV,SAAS;AACb;;AAEA;IACI,kBAAkB;IAClB,UAAU;IACV,iCAAiC;IACjC,eAAe;IACf,gBAAgB;IAChB,iBAAiB;IACjB,SAAS;IACT,cAAc;AAClB;;AAEA;IACI,kBAAkB;IAClB,YAAY;IACZ,YAAY;IACZ,WAAW;IACX,SAAS;IACT,kBAAkB;IAClB,yBAAyB;IACzB,YAAY;IACZ,kBAAkB;IAClB,iBAAiB;IACjB,eAAe;IACf,iCAAiC;IACjC,gBAAgB;IAChB,eAAe;AACnB","sourcesContent":["/* Font imports */\n@font-face {\n    font-family: \"Outfit\";\n    src: url('../fonts/Outfit-VariableFont_wght.ttf') format('truetype');\n}\n@font-face {\n    font-family: \"SF Pro\";\n    src: url('../fonts/SF-Pro.ttf') format('truetype');\n}\n/**/\n\n#header_outer {\n    width: 100%;\n    height: 98px;\n    background: white;\n    filter: drop-shadow(0px 0px 1px rgba(0, 0, 0, .4));\n}\n\n#airline_logo {\n    position: absolute;\n    width: 45px;\n    height: 45px;\n    left: 30px;\n    top: 26px;\n}\n\n#ganderAirways {\n    position: absolute;\n    left: 90px;\n    font-family: \"Outfit\", sans-serif;\n    font-size: 36px;\n    font-weight: 400;\n    line-height: 40px;\n    top: 29px;\n    color: #2550E9;\n}\n\n#return_div {\n    position: absolute;\n    width: 235px;\n    height: 30px;\n    right: 31px;\n    top: 25px;\n    border-radius: 8px;\n    background-color: #3564E8;\n    color: white;\n    text-align: center;\n    padding-top: 12px;\n    font-size: 14px;\n    font-family: \"SF Pro\", sans-serif;\n    font-weight: 600;\n    cursor: pointer;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"header_outer": `Header_header_outer__LjGSe`,
	"airline_logo": `Header_airline_logo__UOTPx`,
	"ganderAirways": `Header_ganderAirways__zEsFZ`,
	"return_div": `Header_return_div__kKGbO`
};
export default ___CSS_LOADER_EXPORT___;
