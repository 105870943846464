export function TrimList(list) {
    let len = list.length;
    if (list[len - 2] == ',') {
        list = list.substring(0, len - 2);
    }
    return list;
}

export function ContainsEligibleFlights(flightList, selectedFlights) {
    for (let i = 0; i < flightList.length; i++) {
        if (selectedFlights[i] && flightList[i].status != FLIGHT_STATUS.UNAFFECTED && flightList[i].status != FLIGHT_STATUS.WEATHER) {
            return true;
        }
    }
    return false;
}

export function getErrorTitle(error) {
    if (error == CLAIM_ERRORS.DATE) {
        return "Hotel On Different Day";
    }
    else if (error == CLAIM_ERRORS.LOCATION) {
        return "Hotel In Incorrect City";
    }
    else if (error == CLAIM_ERRORS.RECEIPT) {
        return "Receipt Not Provided";
    }
    else if (error == CLAIM_ERRORS.COST) {
        return "Cost Exceeds Policy Limit";
    }
    return "";
}

export function getErrorSubtitle(error) {
    if (error == CLAIM_ERRORS.DATE) {
        return `Your flight was cancelled on a different day than shown in your receipt.`;
    }
    else if (error == CLAIM_ERRORS.LOCATION) {
        return `Your flight was cancelled in a different city than shown in your receipt.`;
    }
    else if (error == CLAIM_ERRORS.RECEIPT) {
        return "Your claim is missing a receipt.";
    }
    else if (error == CLAIM_ERRORS.COST) {
        return "Your reimbursement requested exceeds the policy limit set by the Airline.";
    }
    return "";
}

export function getBoolCount(arr) {
    let count = 0;
    for (let i = 0; i < arr.length; i++) {
        if (arr[i]) {
            count++;
        }
    }
    return count;
}

export function isInteger(amount) {
    let amt = parseInt(amount);
    if (isNaN(amt)) {
        let nums = amount.split("$");
        amt = parseInt(nums[nums.length - 1]);
        if (isNaN(amt)) {
            return false;
        }
    }
    return true;
}

export function returnInteger(amount) {
    let amt = parseInt(amount);
    if (isNaN(amt)) {
        let nums = amount.split("$");
        amt = parseInt(nums[nums.length - 1]); 
    }
    return amt;
}

export function isValidUUID(uuid) {
    const regex = /^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i;
    return regex.test(uuid);
}  

export function sendHTTPRequest({link, request, decodeCallback, responseCallback, errorCallback} = {}) {
    fetch(link, request)
    .then(decodeCallback)
    .then(responseCallback)
    .catch(errorCallback);
}

export function getAirportName(abbreviation) {
    switch(abbreviation) {
        case "ORD": return "Chicago O'Hare";
        case "SFO": return "San Francisco";
        default: return "";
    }
}

export function getFlightStatus(delay) {
    if (delay == 0) {
        return FLIGHT_STATUS.UNAFFECTED;
    }
    if (delay < 60*60*4) {
        return FLIGHT_STATUS.SHORT_DELAY;
    }
    return FLIGHT_STATUS.LONG_DELAY;
}

export function getSelectedFlightDates(baseFlights, selectedFlights) {
    let flightDates = [];
    for (let i = 0; i < baseFlights.length; i++) {
        if (selectedFlights[i]) {
            let date = new Date(baseFlights[i].date);
            flightDates.push(date);
        }
    }
    return flightDates;
}

export function getSelectedFlightCodes(baseFlights, selectedFlights) {
    let flightCodes = [];
    for (let i = 0; i < baseFlights.length; i++) {
        if (selectedFlights[i]) {
            flightCodes.push(baseFlights[i].routeStartAbr);
        }
    }
    return flightCodes;
}

export const MENU_STATES = Object.freeze({
    ISSUE: 0,
    LOADING: 1,
    DETAILS: 2,
    CONFIRM: 3,
    RESOLUTION: 4
});

export const DETAILS_STATES = Object.freeze({
    LOOKUP: 0,
    FLIGHT: 1,
    PASSENGERS: 2, 
    ELIGIBILITY: 3,
    CONFIRM: 4,
    COMPLETE: 5,
    FEEDBACK: 6,
    BAGS: 7,
    BAG_ELIGIBILITY: 8,
    BAG_INFORMATION: 9,
    BAG_IDENTIFICATION: 10,
    BAG_CONFIRMATION: 11,
    CHAT: 12
});

export const PAYMENT_STATES = Object.freeze({
    AIRLINE_CREDIT: 0,
    AIRLINE_POINTS: 1,
    ELECTRONIC_FUNDS: 2,
    VENMO: 3,
    UNDECIDED: 4
});

export const BAG_STATUS = Object.freeze({
    DELIVERED: 0,
    PENDING: 1,
    LATE: 2,
})

export const FLIGHT_STATUS = Object.freeze({
    UNAFFECTED: 0,
    CANCELED: 1,
    SHORT_DELAY: 2,
    LONG_DELAY: 3,
    WEATHER: 4
});

export const CLAIM_STATES = Object.freeze({
    CLAIM: 0,
    DISPUTE: 1,
    RESOLUTION: 2,
    LOADING: 3
});

export const CLAIM_STATUS = Object.freeze({
    ACCEPTED: "Accepted",
    DISPUTED: "Disputed",
    REJECTED: "Rejected"
});

export const CLAIM_ERRORS = Object.freeze({
    DATE: 0,
    LOCATION: 1,
    RECEIPT: 2,
    COST: 3,
    TOTAL_COST: 4,
    ALCOHOL: 5
});

export const ISSUE_CATEGORIES = Object.freeze({
    DISRUPTION_REIMBURSEMENT: "Disruption Reimbursement",
    BAG_DAMAGE: "Bag Damage",
    FEEDBACK: "Feedback",
    LOST_BAG: "Lost Baggage",
    PASSENGER_RIGHTS: "Passenger Rights Compensation",
    REFUND_REQUEUST: "Refund Request",
    BAG_SEAT_WIFI: "Bag, Seat, or WiFi Refund",
    INVOLUNTARY_DENIED: "In-Voluntary Denied Boarding",
    VOLUNTARY_DENIED: "Voluntary Denied Boarding"
});

export const LOADING_LOCS = Object.freeze([
    {
        top: "120px", width: "796px", left: "79px"
    },
    {
        top: "150px", width: "606px", left: "162px"
    },
    {
        top: "180px", width: "796px", left: "79px"
    }
]);

export const LOADING_LOCS_SMALL = Object.freeze([
    {
        top: "220px", right: "44px", left: "44px",
    },
    {
        top: "250px", right: "78px", left: "78px",
    },
    {
        top: "280px", right: "44px", left: "44px",
    },
    {
        top: "310px", right: "44px", left: "44px",
    },
    {
        top: "340px", right: "78px", left: "78px",
    },
    {
        top: "370px", right: "44px", left: "44px",
    }
]);

export const DEFAULT_SCENARIO = "S2V23G";
export const DEMO_SCENARIOS = Object.freeze({
    "S2V23G": { // Std reimbursement flow 
        flights: [
            {
                num: "GA 356",
                time: "12pm - 2pm EST",
                routeStart: "Pittsburgh",
                routeStartAbr: "PIT",
                routeEnd: "Baltimore",
                routeEndAbr: "BWI",
                date: "1/12/24",
                status: FLIGHT_STATUS.CANCELED
            },
            {
                num: "GA 357", 
                time: "2pm - 3:30pm EST",
                routeStart: "Baltimore",
                routeStartAbr: "BWI",
                routeEnd: "Pittsburgh",
                routeEndAbr: "PIT",
                date: "1/13/24",
                status: FLIGHT_STATUS.UNAFFECTED
            }
        ],
        passengers: ["Richard Lane", "Jessica Lane", "Jackson Lane"],
        bags: [
            {
                id: "874234321",
                status: BAG_STATUS.DELIVERED,
                lastUpdate: "Delivered to Belt"
            },
            {
                id: "874234322",
                status: BAG_STATUS.PENDING,
                lastUpdate: "1/13, 3:45pm: Loaded off flight, Pittsburgh"
            }
        ],
        loyalty: true,
        airlineInfo: {
            name: "American Airlines",
            policy: {
                hotelReimbursement: 200,
                transportationReimbursement: 50,
                mealReimbursement: 20
            }
        }
    },
    "3ERWT6": { // No available reimbursements
        flights: [
            {
                num: "GA 234",
                time: "8am CST - 9:30am MST",
                routeStart: "Chicago",
                routeStartAbr: "ORD",
                routeEnd: "Denver",
                routeEndAbr: "DEN",
                date: "3/12/24",
                status: FLIGHT_STATUS.WEATHER
            },
            {
                num: "GA 235",
                time: "7pm MST - 10:30pm CST",
                routeStart: "Denver",
                routeStartAbr: "DEN",
                routeEnd: "Chicago",
                routeEndAbr: "ORD",
                date: "3/25/24",
                status: FLIGHT_STATUS.UNAFFECTED
            }
        ],
        bags: [
            {
                id: "817294328",
                status: BAG_STATUS.DELIVERED,
                lastUpdate: "Delivered to Belt"
            },
            {
                id: "817294329",
                status: BAG_STATUS.LATE,
                lastUpdate: "Delivered to Home (1 Day Late)"
            }
        ],
        passengers: ["Nathan Barker"],
        loyalty: false,
        airlineInfo: {
            name: "Delta Airlines",
            policy: {
                hotelReimbursement: 150,
                transportationReimbursement: 90,
                mealReimbursement: 15
            }
        }
    },
    "PXG5YR": { // Dispute flow
        flights: [
            {
                num: "GA 178",
                time: "5pm PST - 8pm PST",
                routeStart: "Seattle",
                routeStartAbr: "SEA",
                routeEnd: "Los Angeles",
                routeEndAbr: "LAX",
                date: "5/2/24",
                status: FLIGHT_STATUS.UNAFFECTED
            },
            {
                num: "GA 179",
                time: "6pm PST - 9pm PST",
                routeStart: "Los Angeles",
                routeStartAbr: "LAX",
                routeEnd: "Seattle",
                routeEndAbr: "SEA",
                date: "5/7/24",
                status: FLIGHT_STATUS.CANCELED
            }
        ],
        bags: [
            {
                id: "482949823",
                status: BAG_STATUS.DELIVERED,
                lastUpdate: "5/2, 8:13pm: Loaded off flight, Los Angeles"
            },
            {
                id: "482949824",
                status: BAG_STATUS.DELIVERED,
                lastUpdate: "5/2, 8:15pm: Loaded off flight, Los Angeles"
            }
        ],
        passengers: ["Victoria Park"],
        loyalty: true,
        airlineInfo: {
            name: "Delta Airlines",
            policy: {
                hotelReimbursement: 150,
                transportationReimbursement: 90,
                mealReimbursement: 15
            }
        }
    },
    "39F66G": {
        flights: [
            {
                num: "GA 100",
                time: "6pm EST - 6am BST",
                routeStart: "New York",
                routeStartAbr: "JFK",
                routeEnd: "London",
                routeEndAbr: "LHR",
                date: "7/12/24",
                status: FLIGHT_STATUS.SHORT_DELAY
            },
            {
                num: "GA 101",
                time: "12pm BST - 4:30pm EST",
                routeStart: "London",
                routeStartAbr: "LHR",
                routeEnd: "New York",
                routeEndAbr: "JFK",
                date: "7/14/24",
                status: FLIGHT_STATUS.UNAFFECTED
            }
        ],
        bags: [
            {
                id: "482949823",
                status: BAG_STATUS.PENDING,
                lastUpdate: "Delivered to Belt"
            },
            {
                id: "482949824",
                status: BAG_STATUS.PENDING,
                lastUpdate: "Delivered to Belt"
            }
        ],
        passengers: ["Alex Brandt"],
        loyalty: false,
        receiptApproval: "stern"
    },
    "E0DFM9": {
        flights: [
            {
                num: "GA 110",
                time: "6:30am BST - 10am CEST",
                routeStart: "London",
                routeStartAbr: "LHR",
                routeEnd: "Rome",
                routeEndAbr: "FCO",
                date: "7/22/24",
                status: FLIGHT_STATUS.LONG_DELAY
            },
            {
                num: "GA 111",
                time: "1pm CEST - 2:30 BST",
                routeStart: "Rome",
                routeStartAbr: "FCO",
                routeEnd: "London",
                routeEndAbr: "LHR",
                date: "7/24/24",
                status: FLIGHT_STATUS.UNAFFECTED
            }
        ],
        bags: [
            {
                id: "482949823",
                status: BAG_STATUS.LATE,
                lastUpdate: "Delivered to Home (2 Days Late)"
            },
            {
                id: "482949824",
                status: BAG_STATUS.LATE,
                lastUpdate: "Delivered to Home (2 Days Late)"
            }
        ],
        passengers: ["Molly Santos"],
        loyalty: true,
        receiptApproval: "light"
    }
});

export const BASE_STATE = Object.freeze({
    menuState: MENU_STATES.LOADING,
    detailsState: DETAILS_STATES.LOOKUP,
    detailsStatesVisited: [true, false, false, false, false, false, false, false, false, false, false, false, false],
    searchTerm: "",
    baseData: {},
    data: {
        category: -1,
        confirmationCode: "",
        flights: [],
        bags: [],
        containsEligibleFlights: false,
        passengers: [],
        claims: [],
        bagClaims: [],
        paymentForm: 4,
        paymentAmount: "",
        paymentDetails: "",
        bagMatches: [],
        bagMatch: -1
    }
})
export let seenFeedbackCategories = [];

export const FEEDBACK_RESPONSE = Object.freeze({
    TIER0: 0,
    TIER1: 1,
    TIER2: 2,
    REDIRECT_TO_GANDER: 3,
    LIVE_SUPPORT: 4,
    OTHER: 5,
    POSITIVE: 6
})

export const FEEDBACK_CATEGORIES = Object.freeze({
    "Food & Beverage Service Failure": {"issueText": "issues with our on-board service", "department": "on-board catering", "comp": 0, "goldEligible": true, "escalation": FEEDBACK_RESPONSE.TIER0},
    "Boarding Process": {"issueText": "issues with our boarding process", "department": "airport experience", "comp": 0, "goldEligible": false, "escalation": FEEDBACK_RESPONSE.TIER0},
    "In-Operable Lavatory": {"issueText": "issues onboard our aircraft", "department": "maintenance", "comp": 5000, "goldEligible": false, "escalation": FEEDBACK_RESPONSE.TIER1},
    "Missed Connection": {"issueText": "issues with completing your journey", "department": "customer experience", "comp": 5000, "goldEligible": false, "escalation": FEEDBACK_RESPONSE.TIER1},
    "Poor Phone or Chat Support Experience": {"issueText": "issues with getting support", "department": "customer support", "comp": 0, "goldEligible": true, "escalation": FEEDBACK_RESPONSE.TIER0},
    "App or Website Issue": {"issueText": "issues with our digital products", "department": "technology", "comp": 0, "goldEligible": false, "escalation": FEEDBACK_RESPONSE.LIVE_SUPPORT},
    "Pricing Discrepancy": {"issueText": "issues with our fares", "department": "pricing", "comp": 0, "goldEligible": false, "escalation": FEEDBACK_RESPONSE.LIVE_SUPPORT},
    "Cabin Temperature": {"issueText": "discomfort onboard our aircraft", "department": "aircraft experience", "comp": 0, "goldEligible": true, "escalation": FEEDBACK_RESPONSE.TIER0},
    "Aircraft Cleanliness": {"issueText": "issues onboard our aircraft", "department": "maintenance", "comp": 5000, "goldEligible": false, "escalation": FEEDBACK_RESPONSE.TIER1},
    "In-Flight Entertainment failure": {"issueText": "issues onboard our aircraft", "department": "maintenance", "comp": 5000, "goldEligible": false, "escalation": FEEDBACK_RESPONSE.TIER1},
    "No Seat Recline": {"issueText": "issues onboard our aircraft", "department": "maintenance", "comp": 5000, "goldEligible": false, "escalation": FEEDBACK_RESPONSE.TIER1},
    "Flight Quality & Turbulance": {"issueText": "issues onboard our aircraft", "department": "chief pilot", "comp": 0, "goldEligible": false, "escalation": FEEDBACK_RESPONSE.TIER0},
    "Delay & Cancellation Impact": {"issueText": "issues with completing your journey", "department": "customer experience", "comp": 0, "goldEligible": true, "escalation": FEEDBACK_RESPONSE.TIER0},
    "Fellow Passenger Behavior": {"issueText": "issues onboard our aircraft", "department": "customer experience", "comp": 0, "goldEligible": false, "escalation": FEEDBACK_RESPONSE.TIER0},
    "Lounge Crowding": {"issueText": "issues in our lounges", "department": "lounge experience", "comp": 5000, "goldEligible": false, "escalation": FEEDBACK_RESPONSE.TIER1},
    "Flight Attendant Behavior": {"issueText": "issues with our crew", "department": "customer experience", "comp": 5000, "goldEligible": false, "escalation": FEEDBACK_RESPONSE.TIER1},
    "Check-In Attendant Behavior": {"issueText": "issues with our crerw", "department": "customer experience", "comp": 5000, "goldEligible": false, "escalation": FEEDBACK_RESPONSE.TIER1},
    "Baggage Complaints": {"issueText": "issues with your bags", "department": "bag services", "comp": 0, "goldEligible": false, "escalation": FEEDBACK_RESPONSE.REDIRECT_TO_GANDER},
    "Lack of Loyalty Recognition": {"issueText": "issues with your loyalty experience", "department": "Gander rewards", "comp": 0, "goldEligible": true, "escalation": FEEDBACK_RESPONSE.TIER0},
    "Sickness Caused by Airline": {"issueText": "issues with your journey with us", "department": "customer incidents", "comp": 10000, "goldEligible": false, "escalation": FEEDBACK_RESPONSE.TIER2},
    "Bodily Harm Caused by Airline": {"issueText": "issues with your journey with us", "department": "customer incidents", "comp": 10000, "goldEligible": false, "escalation": FEEDBACK_RESPONSE.TIER2},
    "Mis-Handling of Important Item": {"issueText": "issues with your journey with us", "department": "customer incidents", "comp": 10000, "goldEligible": false, "escalation": FEEDBACK_RESPONSE.TIER2},
    "Wheelchair or Assistive Device Failure": {"issueText": "issues with your journey with us", "department": "customer incidents", "comp": 10000, "goldEligible": false, "escalation": FEEDBACK_RESPONSE.TIER2},
    "Unaccompanied Minor Failure": {"issueText": "issues with your journey with us", "department": "customer incidents", "comp": 10000, "goldEligible": false, "escalation": FEEDBACK_RESPONSE.TIER2},
    "Sick or Elderly Individual Failure": {"issueText": "issues with your loved one's journey with us", "department": "customer incidents", "comp": 10000, "goldEligible": false, "escalation": FEEDBACK_RESPONSE.TIER2},
    "Premium Cabin Experience": {"issueText": "issues with your business class experience", "department": "premium services", "comp": 10000, "goldEligible": false, "escalation": FEEDBACK_RESPONSE.TIER0},
    "Extraneous Fees": {"issueText": "issues with our fees", "department": "customer policies", "comp": -1, "goldEligible": false, "escalation": FEEDBACK_RESPONSE.LIVE_SUPPORT},
    "Wheelchair Not Available": {"issueText": "issues with your wheelchair", "department": "customer incidents", "comp": 10000, "goldEligible": false, "escalation": FEEDBACK_RESPONSE.TIER2},
    "Compliment": {"issueText": "", "department": "employee recognition", "comp": 0, "goldEligible": false, "escalation": FEEDBACK_RESPONSE.POSITIVE},
    "Other": {"issueText": "issues with your journey with us", "department": "customer experience", "comp": 0, "goldEligible": true, "escalation": FEEDBACK_RESPONSE.OTHER}
});
/*
export const FEEDBACK_CATEGORIES = Object.freeze({
    FOOD_BEV: {"id": "Food & Beverage Service Failure", "issueText": "issues with our on-board service", "department": "on-board catering", "comp": 0, "goldEligible": true, "escalation": FEEDBACK_RESPONSE.TIER0},
    BOARDING: {"id": "Boarding Process", "issueText": "issues with our boarding process", "department": "airport experience", "comp": 0, "goldEligible": false, "escalation": FEEDBACK_RESPONSE.TIER0},
    LAVATORY: {"id": "In-Operable Lavatory", "issueText": "issues onboard our aircraft", "department": "maintenance", "comp": 5000, "goldEligible": false, "escalation": FEEDBACK_RESPONSE.TIER1},
    CONNECTION: {"id": "Missed Connection", "issueText": "issues with completing your journey", "department": "customer experience", "comp": 5000, "goldEligible": false, "escalation": FEEDBACK_RESPONSE.TIER1},
    SUPPORT: {"id": "Poor Phone or Chat Support Experience", "issueText": "issues with getting support", "department": "customer support", "comp": 0, "goldEligible": true, "escalation": FEEDBACK_RESPONSE.TIER0},
    APP_WEB: {"id": "App or Website Issue", "issueText": "issues with our digital products", "department": "technology", "comp": 0, "goldEligible": false, "escalation": FEEDBACK_RESPONSE.LIVE_SUPPORT},
    PRICING: {"id": "Pricing Discrepancy", "issueText": "issues with our fares", "department": "pricing", "comp": 0, "goldEligible": false, "escalation": FEEDBACK_RESPONSE.LIVE_SUPPORT},
    TEMPERATURE: {"id": "Cabin Temperature", "issueText": "discomfort onboard our aircraft", "department": "aircraft experience", "comp": 0, "goldEligible": true, "escalation": FEEDBACK_RESPONSE.TIER0},
    CLEANLINESS: {"id": "Aircraft Cleanliness", "issueText": "issues onboard our aircraft", "department": "maintenance", "comp": 5000, "goldEligible": false, "escalation": FEEDBACK_RESPONSE.TIER1},
    IFE: {"id": "In-Flight Entertainment failure", "issueText": "issues onboard our aircraft", "department": "maintenance", "comp": 5000, "goldEligible": false, "escalation": FEEDBACK_RESPONSE.TIER1},
    SEAT: {"id": "No Seat Recline", "issueText": "issues onboard our aircraft", "department": "maintenance", "comp": 5000, "goldEligible": false, "escalation": FEEDBACK_RESPONSE.TIER1},
    TURBULANCE: {"id": "Flight Quality & Turbulance", "issueText": "issues onboard our aircraft", "department": "chief pilot", "comp": 0, "goldEligible": false, "escalation": FEEDBACK_RESPONSE.TIER0},
    DELAY_CANCEL: {"id": "Delay & Cancellation Impact", "issueText": "issues with completing your journey", "department": "customer experience", "comp": 0, "goldEligible": true, "escalation": FEEDBACK_RESPONSE.TIER0},
    PASSENGER: {"id": "Fellow Passenger Behavior", "issueText": "issues onboard our aircraft", "department": "customer experience", "comp": 0, "goldEligible": false, "escalation": FEEDBACK_RESPONSE.TIER0},
    LOUNGE: {"id": "Lounge Crowding", "issueText": "issues in our lounges", "department": "lounge experience", "comp": 5000, "goldEligible": false, "escalation": FEEDBACK_RESPONSE.TIER1},
    ATTENDANT: {"id": "Flight Attendant Behavior", "issueText": "issues with our crew", "department": "customer experience", "comp": 5000, "goldEligible": false, "escalation": FEEDBACK_RESPONSE.TIER1},
    CHECK_IN: {"id": "Check-In Attendant Behavior", "issueText": "issues with our crerw", "department": "customer experience", "comp": 5000, "goldEligible": false, "escalation": FEEDBACK_RESPONSE.TIER1},
    BAGGAGE: {"id": "Baggage Complaints", "issueText": "issues with your bags", "department": "bag services", "comp": 0, "goldEligible": false, "escalation": FEEDBACK_RESPONSE.REDIRECT_TO_GANDER},
    LOYALTY: {"id": "Lack of Loyalty Recognition", "issueText": "issues with your loyalty experience", "department": "Gander rewards", "comp": 0, "goldEligible": true, "escalation": FEEDBACK_RESPONSE.TIER0},
    SICKNESS: {"id": "Sickness Caused by Airline", "issueText": "issues with your journey with us", "department": "customer incidents", "comp": 10000, "goldEligible": false, "escalation": FEEDBACK_RESPONSE.TIER2},
    HARM: {"id": "Bodily Harm Caused by Airline", "issueText": "issues with your journey with us", "department": "customer incidents", "comp": 10000, "goldEligible": false, "escalation": FEEDBACK_RESPONSE.TIER2},
    IMPORTANT_ITEM: {"id": "Mis-Handling of Important Item", "issueText": "issues with your journey with us", "department": "customer incidents", "comp": 10000, "goldEligible": false, "escalation": FEEDBACK_RESPONSE.TIER2},
    WHEELCHAIR_ERR: {"id": "Wheelchair or Assistive Device Failure", "issueText": "issues with your journey with us", "department": "customer incidents", "comp": 10000, "goldEligible": false, "escalation": FEEDBACK_RESPONSE.TIER2},
    MINOR: {"id": "Unaccompanied Minor Failure", "issueText": "issues with your journey with us", "department": "customer incidents", "comp": 10000, "goldEligible": false, "escalation": FEEDBACK_RESPONSE.TIER2},
    ELDERLY: {"id": "Sick or Elderly Individual Failure", "issueText": "issues with your loved one's journey with us", "department": "customer incidents", "comp": 10000, "goldEligible": false, "escalation": FEEDBACK_RESPONSE.TIER2},
    PREMIUM_CABIN: {"id": "Premium Cabin Experience", "issueText": "issues with your business class experience", "department": "premium services", "comp": 10000, "goldEligible": false, "escalation": FEEDBACK_RESPONSE.TIER0},
    FEES: {"id": "Extraneous Fees", "issueText": "issues with our fees", "department": "customer policies", "comp": -1, "goldEligible": false, "escalation": FEEDBACK_RESPONSE.LIVE_SUPPORT},
    WHEELCHAIR_NA: {"id": "Wheelchair Not Available", "issueText": "issues with your wheelchair", "department": "customer incidents", "comp": 10000, "goldEligible": false, "escalation": FEEDBACK_RESPONSE.TIER2},
    COMPLIMENT: {"id": "Compliment", "issueText": "", "department": "", "comp": 0, "goldEligible": false, "escalation": FEEDBACK_RESPONSE.POSITIVE},
    OTHER: {"id": "Other", "issueText": "issues with your journey with us", "department": "customer experience", "comp": 0, "goldEligible": true, "escalation": FEEDBACK_RESPONSE.OTHER}
});
*/

export function baggage_scope(item) {
    return `You are a knowledgable customer service agent for Gander Airways.
        You are tasked with determining whether a customer's reimbursement claim for a specific item is reasonable.
        Output for scope is a score of 0 to 100 where 0 is a fairly egregious claim and 100 is a claim that is obviously in scope.
        Here is the item in question:
        <item>
        ${item}
        </item>
        Please return only with the score, nothing else.
        `
}

export function classification_prompt(input) {
    return `You are a friendly and knowledgable customer service agent for Gander Airways.

        You are tasked with categorizing customer feedback. You will be given a customer's input, and your job is to determine which category of feedback it falls into.

        Here is the customer's input:
        <customer_input>
        ${input}
        </customer_input>

        Carefully analyze the customer's input and determine which category best fits their feedback. Consider the main focus of their grievance and any specific details they provide.

        First, write out your reasoning and analysis of how to classify this request inside <reasoning> tags.
        Then, output the appropriate classification label for the request inside a <category> tag. The valid categories are:
        <categories>
        <category>Food & Beverage Service Failure</category>
        <category>Boarding Process</category>
        <category>In-Operable Lavatory</category>
        <category>Missed Connection</category>
        <category>Poor Phone or Chat Support Experience</category>
        <category>App or Website Issue</category>
        <category>Pricing Discrepancy</category>
        <category>Cabin Temperature</category>
        <category>Aircraft Cleanliness</category>
        <category>In-Flight Entertainment failure</category>
        <category>No Seat Recline</category>
        <category>Flight Quality & Turbulance</category>
        <category>Delay & Cancellation Impact</category>
        <category>Fellow Passenger Behavior</category>
        <category>Lounge Crowding</category>
        <category>Flight Attendant Behavior</category>
        <category>Check-In Attendant Behavior</category>
        <category>Baggage Complaints</category>
        <category>Lack of Loyalty Recognition</category>
        <category>Sickness Caused by Airline</category>
        <category>Bodily Harm Caused by Airline</category>
        <category>Mis-Handling of Important Item</category>
        <category>Wheelchair or Assistive Device Failure</category>
        <category>Unaccompanied Minor Failure</category>
        <category>Sick or Elderly Individual Failurre</category>
        <category>Premium Cabin Experience</category>
        <category>Extraneous Fees</category>
        <category>Wheelchair Not Available</category>
        <category>Compliment</category>
        <category>Other</category>
        </categories>

        A request may have ONLY ONE applicable category. Only include the category that is most applicable to the request.
        Remember to choose only one category that best represents the main issue in the customer's input. If the complaint doesn't clearly fit into any of the specific categories, use the "Other" category.
        If the customer is not facing any issues, and instead had a positive experience, use the "Compliment" category

        Here are a few examples:
        <examples>
        <example>
            <request>I waited for the entire plane to be served and by the time it got to me, I didn’t get my choice of meal. This is RIDICULOUS. I will never fly Gander Airways again!! </request>
            <reasoning>This customer had a poor experience with the food and beverage service</reasoning>
            <category>Food & Beverage Service Failure</category>
        </example>
        <example>
            <request>Getting on my last flight with Gander was AWFUL. I am a premier member and I expect to be able to board early and not have to gate check my bag.</request>
            <reasoning>This customer was not permitted to board early despite their flight status.</reasoning>
            <category>Boarding Process</category>
        </example>
        <example>
            <request>The bathrooms on this flight did not work at all. There was only one that worked, it smelled awful, and ran out of toilet paper. I expect a lot better.</request>
            <reasoning>Customer is addressing a bathroom related issue.</reasoning>
            <category>In-Operable Lavatory</category>
        </example>
        <example>
            <request>My whole trip is totally ruined. This is ridiculous. My first flight was delayed due to weather and when I got to Dallas, I missed my connection by 4 minutes. You would think they would know we are making a tight connection and hold the plane for a few minutes, but they did absolutely nothing. I expect, when I book a flight with Gander, to get where I need to go and not get stranded in the middle of nowhere with no recourse. I want my money back!</request>
            <reasoning>The customer's problems are driven by them missing their connection.</reasoning>
            <category>Missed Connection</category>
        </example>
        <example>
            <request>The chat agent Lisa was very rude and super unhelpful - you should fire her</request>
            <reasoning>Customer references their digital support channel</reasoning>
            <category>Poor Phone or Chat Support Experience</category>
        </example>
        <example>
            <request>The website is not letting me select my seat - how do I select my seat?</request>
            <reasoning>The customer is having issues with the digital channel for making bookings and interacting with the airline.</reasoning>
            <category>App or Website Issue</category>
        </example>
        <example>
            <request>Me and my husband are seeing different prices for the same flight. Can you explain why, this is ridiculous!</request>
            <reasoning>The customer references price and the difference between posted prices.</reasoning>
            <category>Pricing Discrepancy</category>
        </example>
        <example>
            <request>I was freezing the entire flight and got sick because of the temperature.</request>
            <reasoning>The customer had sn issue with the temperature in the aircraft.</reasoning>
            <category>Cabin Temperature</category>
        </example>
        <example>
            <request>My seat was disgusting, there was gum on the back of the seat and it was obvious that the seat had not been cleaned - please clean your aircraft better in the future.</request>
            <reasoning>The customer discusses cleanliness in relation to the seat and the aircraft.</reasoning>
            <category>Aircraft Cleanliness</category>
        </example>
        <example>
            <request>My seat back entertainment did not work the entire flight. I asked the flight attendant to reset 3 times and It did not work! This is ridiculous</request>
            <reasoning>The customer discusses their in flight entertainment (IFE).</reasoning>
            <category>In-Flight Entertainment Failure</category>
        </example>
        <example>
            <request>I tried so many times but my seat could not recline. It was so annoying, I paid good money for this flight and I was stuck in an upright position the whole flight. I asked the flight attendant to reset it but they could not do anything</request>
            <reasoning>The seat recline issue is the chief complaint.</reasoning>
            <category>No Seat Recline</category>
        </example>
        <example>
            <request>The flight was so bumpy and the turbulence was terrible - you need to tell your pilots to avoid the turbulence. When I fly Delta there is no turbulence.</request>
            <reasoning>Customer discusses a physical characteristic of the flight that is likely outside of the airlines control including hard landings, turbulence, steep climb, sharp drops, or out of the way routing, circling, and/or flight duration.</reasoning>
            <category>Flight Quality & Turbulance</category>
        </example>
        <example>
            <request>I could not attend my kid’s birthday party because you delayed my flight coming back to Chicago. This is ridiculous and I will NEVER fly your airline again.</request>
            <reasoning>Customer dscusses a physical characteristic of the flight that is likely outside of the airlines control including hard landings, turbulence, steep climb, sharp drops, or out of the way routing, circling, and/or flight duration</reasoning>
            <category>Delay & Cancellation Impact</category>
        </example>
        <example>
            <request>Kids were screaming the entire time during the flight and I could not sleep the entire flight. They should make kid-free seating zones - this was so annoying.</request>
            <reasoning>The customer is discussing other passengers and how their actions are affecting the customer.</reasoning>
            <category>Fellow Passenger Behavior</category>
        </example>
        <example>
            <request>The lounge line was completely out the door. I pay a lot of money for lounge access and I could not even get inside. This was so annoying</request>
            <reasoning>The customer's lounge experience is degraded by crowds or lack of availability.</reasoning>
            <category>Lounge Crowding</category>
        </example>
        <example>
            <request>Tina the head purser on GA100 was incredibly rude to me the entire flight. When I asked if I can move to an empty row, she accosted me. It makes no sense to me why I could not sit there when the row was completely empty. I think this is entirely ridiculous - and I am not sure if she should be working at Gander.</request>
            <reasoning>Directly addresses onboard crew and their interaction as being the primary complaint area.</reasoning>
            <category>Flight Attendant Behavior</category>
        </example>
        <example>
            <request>The check in agent had no idea what he was doing. He tried to charge me $35 for my bag despite me having the Gander credit card. He needs to read up on the airlines policy. It was ridiculous. I am so upset - I deserve better as a loyal customer</request>
            <reasoning>Addresses a staff issue in the airport, prior to boarding.</reasoning>
            <category>Check-In Attendant Behavior</category>
        </example>
        <example>
            <request>My bags took 45 minutes to come out and came out on the wrong belt - you guys should probably fix that</request>
            <reasoning>The complaint relates to baggage delivery.</reasoning>
            <category>Baggage Complaints</category>
        </example>
        <example>
            <request>Give me my money back!!!</request>
            <reasoning>This is a vague complaint that would be difficult to ascribe to a category.</reasoning>
            <category>Other</category>
        </example>
        <example>
            <request>I spend $20,000+ a year with Gander. I expect to get the benefits that are promised to me given my loyalty level. On my most recent flight, not one employee treated me with the right loyalty level. I am so upset - I should get priority boarding.</request>
            <reasoning>Discusses loyalty and a failure to deliver the loyalty level</reasoning>
            <category>Lack of Loyalty Recognition</category>
        </example>
        <example>
            <request> You are such a good airline and your agent helped me get on my way - thank you so much!</request>
            <reasoning>The customer had a positive experience with the airline.</reasoning>
            <category>Compliment</category>
        </example>
        <example>
            <request>I got food poisoning from the undercooked chicken served in business class. This is absolutely ridiculous. I want a FULL REFUND.</request>
            <reasoning>The customer's sickness was caused by food served on their flight.</reasoning>
            <category>Sickness Caused by Airline</category>
        </example>
        <example>
            <request>One of your rude and carless agents opened the overhead bin right onto of my head. I want them to be fired and I wanted to be compensated for this. I want a full investigation to be launched. Their badge number is: 92382323</request>
            <reasoning>The customer was hit in the head by an airline employee with the overhead bin.</reasoning>
            <category>Bodily Harm Caused by Airline</category>
        </example>
        <example>
            <request>You lost my mother’s ashes, I’ll never fly your airline again! The flight agent said I couldn’t carry it on board and it had to get gate checked. Then I come to find out that it has gone missing. There is no way to replace this.</request>
            <reasoning>This customer is complaining about a significant personal loss of an item or an experience which is troubling.</reasoning>
            <category>Mis-Handling of Important Item</category>
        </example>
        <example>
            <request>I checked in my wheelchair and you broke it. This is absolutely ridiculous and I will never forgive you for this.</request>
            <reasoning>The customer experienced their wheelchair being broken. Damage to assistive devices overrides other categorizations.</reasoning>
            <category>Wheelchair or Assistive Device Failure</category>
        </example>
        <example>
            <request>I used and paid for the unaccompanied minor service and you left my kid high and dry. How dare you! I want a full refund and want to speak to management immediately. This is completely ridiculous and I can’t believe you did this to me and my child</request>
            <reasoning>The unaccompanied minor was in duty of the airline and the customer had a poor experience and/or a service failure.</reasoning>
            <category>Unaccompanied Minor Failure</category>
        </example>
        <example>
            <request>I booked a flight for my parents to fly from San Fransisco to New York stopping in Minneapolis. They had an awful experience. My mom has diabetes and the agent required her bag with medicine to get check in. The flight got delayed and her blood sugar dropped dangerously low. I trusted my parents in the care of Gander Airways and they completely let me down.</request>
            <reasoning>The customer references parents or travelers that are sick that were not properly being taken care of.</reasoning>
            <category>Sick or Elderly Individual Failure</category>
        </example>
            <request>I flew in business class on Gander from JFK to London. I was really let down with the quality of the food, service, and the seat in business class. I paid a lot of money for this ticket and on your competitors like Emirates, I get a much better experience. This is just super frustrating and I wish you would improve your business class. I think if you add a bit more padding to the business class seat, it would be much better.</request>
            <reasoning>The customer makes multiple references to premium cabins. Their problem stems from high expectations for premium cabins.</reasoning>
            <category>Premium Cabin Experience</category>
        </example>
        <example>
            <request>I was charged $70 dollars for an overweight bag that you ended up losing. I want a refund and think you should lower your bag fees.</request>
            <reasoning>References a fee they feel they erroneously paid.</reasoning>
            <category>Extranous Fees</category>
        </example>
        <example>
            <request>I requested a wheelchair in Dallas because I just got surgery and I could not walk. I made the request on the phone and confirmed a day before my flight. When I got to the airport, there was no one available to help me. I had to sit and beg for 1 hour even to get someone to notice me. This is not how I expect to be treated and I think you should allocate more wheelchairs for passengers like me</request>
            <reasoning>Discusses failure in providing a wheelchair for the customer</reasoning>
            <category>Wheelchair Not Available</category>
        </example>
        </examples>`;
    }
