import React from 'react';
import styles from './Passengers.module.css';
import globalStyles from '../global.module.css';
import { DETAILS_STATES, LOADING_LOCS, TrimList, ISSUE_CATEGORIES } from '../util';

import checkmark from './images/checkmark.svg';
import chevWhite from './images/chevWhite.svg';
import chevGrey from './images/chevGrey.svg';
import arrow from './images/arrow.svg';

class IndividualPassenger extends React.Component {
    render() {
        return (
            <div className={styles.name} style={{top: `${81 + 71 * this.props.index}px`}} onClick={() => this.props.changePassengers(this.props.index)}>
                {
                    this.props.data.passengers[this.props.index] ? 
                    <div className={styles.selected}>
                        <img src={checkmark} className={styles.selectedImg} />
                    </div>
                    :
                    <div className={styles.selector} />
                }
                <div className={styles.nameText}>{this.props.baseData.passengers[this.props.index]}</div>
            </div>
        );
    }
}

class Passengers extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        if (this.props.state > DETAILS_STATES.FLIGHT && this.props.data.passengers.length == 1) {
            if (this.props.state == DETAILS_STATES.PASSENGERS) {
                this.props.updateState(DETAILS_STATES.ELIGIBILITY);
            }
            return (<div />);
        }
        if (this.props.data.category == ISSUE_CATEGORIES.BAG_DAMAGE || this.props.data.category == ISSUE_CATEGORIES.LOST_BAG) {
            if (this.props.state == DETAILS_STATES.PASSENGERS) {
                this.props.updateState(DETAILS_STATES.BAGS);
            }
            return (<div />);
        }
        else if (this.props.state == DETAILS_STATES.PASSENGERS) {
            if (!this.props.data.containsEligibleFlights) {
                this.props.updateState(DETAILS_STATES.ELIGIBILITY);
            }
            let allPassengersSelected = true;
            let anyPassengersSelected = false;
            for (let i = 0; i < this.props.data.passengers.length; i++) {
                if (this.props.data.passengers[i] == false) {
                    allPassengersSelected = false;
                }
                else {
                    anyPassengersSelected = true;
                }
            }
            return (
                <div>
                <div className={globalStyles.stepOuter} style={{height: "400px", marginBottom: "-23px"}}>
                    <div className={globalStyles.stepTitle}>Select Passengers</div>
                    {
                        this.props.baseData.passengers.map((_, index) => 
                            <IndividualPassenger 
                                key={index} 
                                index={index} 
                                data={this.props.data} 
                                baseData={this.props.baseData} 
                                changePassengers={this.props.changePassengers} />)
                    }
                    <div id={styles.selectAll} onClick={() => this.props.changePassengers(this.props.data.passengers.length)}>
                        {
                            allPassengersSelected ? 
                            <div className={styles.selected}>
                                <img src={checkmark} className={styles.selectedImg} />
                            </div>
                            :
                            <div className={styles.selector} />
                        }
                        <div className={styles.nameText}>All Passengers</div>
                    </div>
                </div>
                {
                    anyPassengersSelected ? 
                    <div className={globalStyles.continue} onClick={() => this.props.updateState(DETAILS_STATES.ELIGIBILITY)}>
                        <div id={styles.selectText} style={{color: "white"}}>Confirm Eligibility</div>
                        <img src={chevWhite} className={globalStyles.continueArrow} />
                    </div>
                    :
                    <div className={globalStyles.continueDisabled}>
                        <div id={styles.selectText} style={{color: "#A7A7A7"}}>Select to Continue</div>
                        <img src={chevGrey} className={globalStyles.continueArrow} />
                    </div>
                }
                </div>
            );
        }
        else if (this.props.state < DETAILS_STATES.PASSENGERS && !this.props.visited[DETAILS_STATES.PASSENGERS]) {
            return (
                <div className={`${globalStyles.stepOuter} ${globalStyles.stepOuterPreview}`}>
                    <div className={`${globalStyles.stepTitle} ${globalStyles.stepTitlePreview}`}>Select Passengers</div>
                    {
                        LOADING_LOCS.map((locs, index) => <div key={index} className={globalStyles.loading} style={locs} />)
                    }
                </div>
            );
        }
        else {
            let nameText = "";
            for (let i = 0; i < this.props.data.passengers.length; i++) {
                if (this.props.data.passengers[i]) {
                    nameText += `${this.props.baseData.passengers[i]}, `;
                }
            }
            nameText = TrimList(nameText);
            if (!this.props.data.containsEligibleFlights) {
                return (<div />);
            }
            return (
                <div className={`${globalStyles.stepOuter} ${globalStyles.stepOuterVisited}`}>
                    <div className={`${globalStyles.stepTitle} ${globalStyles.stepTitleVisited}`} style={{textOverflow: "ellipsis"}}>Claiming for: {nameText}</div>
                    <div className={globalStyles.changeTrip} onClick={() => this.props.updateState(DETAILS_STATES.PASSENGERS)}>
                        <div className={globalStyles.changeTripText}>Change Selection</div>
                        <img src={arrow} className={globalStyles.changeTripImg} />
                    </div>
                </div>
            );
        }
    }
}

export default Passengers;