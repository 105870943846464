import React from 'react';
import styles from './Header.module.css';

import ganderAirways from '../Form/images/ganderair.png';

class Header extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
      return (
        <div id={styles.header_outer}>
          <img id={styles.airline_logo} src={ganderAirways} />
          <div id={styles.ganderAirways}><b>Gander</b> Airways</div>
          <div id={styles.return_div}>
            Back to usegander.com
          </div>
        </div>
      );
    }
}

export default Header;