import React from 'react';
import styles from './Menu.module.css';

import menu0 from './images/menu0.svg';
import menu1 from './images/menu1.svg';
import menu2 from './images/menu2.svg';
import menu0_blue from './images/menu0_blue.svg';
import menu1_blue from './images/menu1_blue.svg';
import menu2_blue from './images/menu2_blue.svg';
import menu1_dark from './images/menu1_dark.svg';
import line from './images/line.svg';

class Menu extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        if (this.props.state == 0)
        {
            return (
                <div id={styles.menu_outer}>
                    <img src={line} id={styles.line} />
                    <div className={styles.title} style={{top: "51px", color: "#3564E8"}}>Pick an Issue</div>
                    <div className={styles.subtitle} style={{top: "78px"}}>Select or search</div>
                    <div id={styles.circle1} style={{border: "2px solid #3564E8"}}>
                        <img src={menu0_blue} className={styles.img} />
                    </div>
                    <div className={styles.title} style={{top: "185px", color: "#979797"}}>Provide Us Details</div>
                    <div className={styles.subtitle} style={{top: "214px", color: "#979797"}}>Give us more details about your issue</div>
                    <div id={styles.circle2}>
                        <img src={menu1} className={styles.img} />
                    </div>
                    <div className={styles.title} style={{top: "323px", color: "#979797"}}>Get a Resolution</div>
                    <div className={styles.subtitle} style={{top: "350px", color: "#979797"}}>Our system will solve your issue</div>
                    <div id={styles.circle3}>
                        <img src={menu2} id={styles.img2} />
                    </div>
                </div>
            );
        }
        else if (this.props.state == 1)
        {
            return (
                <div id={styles.menu_outer}>
                    <img src={line} id={styles.line} />
                    <div className={styles.title} style={{top: "51px"}}>Pick an Issue</div>
                    <div className={styles.subtitle} style={{top: "78px"}}>Select or search</div>
                    <div id={styles.circle1}>
                        <img src={menu0} className={styles.img} />
                    </div>
                    <div className={styles.title} style={{top: "185px", color: "#3564E8"}}>Provide Us Details</div>
                    <div className={styles.subtitle} style={{top: "214px"}}>Give us more details about your issue</div>
                    <div id={styles.circle2} style={{border: "2px solid #3564E8"}}>
                        <img src={menu1_blue} className={styles.img} />
                    </div>
                    <div className={styles.title} style={{top: "323px", color: "#979797"}}>Get a Resolution</div>
                    <div className={styles.subtitle} style={{top: "350px", color: "#979797"}}>Our system will solve your issue</div>
                    <div id={styles.circle3}>
                        <img src={menu2} id={styles.img2} />
                    </div>
                </div>
            );
        }
        else if (this.props.state == 2)
        {
            return (
                <div id={styles.menu_outer}>
                    <img src={line} id={styles.line} />
                    <div className={styles.title} style={{top: "51px"}}>Pick an Issue</div>
                    <div className={styles.subtitle} style={{top: "78px"}}>Select or search</div>
                    <div id={styles.circle1}>
                        <img src={menu0} className={styles.img} />
                    </div>
                    <div className={styles.title} style={{top: "185px"}}>Provide Us Details</div>
                    <div className={styles.subtitle} style={{top: "214px"}}>Give us more details about your issue</div>
                    <div id={styles.circle2}>
                        <img src={menu1_dark} className={styles.img} />
                    </div>
                    <div className={styles.title} style={{top: "323px", color: "#3564E8"}}>Get a Resolution</div>
                    <div className={styles.subtitle} style={{top: "350px"}}>Our system will solve your issue</div>
                    <div id={styles.circle3} style={{border: "2px solid #3564E8"}}>
                        <img src={menu2_blue} id={styles.img2} />
                    </div>
                </div>
            );
        }
        else {
            return (<div />);
        }
}
}

export default Menu;