import React from 'react';
import styles from './Resolution.module.css';
import globalStyles from'../global.module.css';

import { FEEDBACK_CATEGORIES, FEEDBACK_RESPONSE, ISSUE_CATEGORIES, seenFeedbackCategories } from '../util';

import checkmark from './images/checkmark.svg';
import heart from './images/heart.svg';
import ganderAir from './images/ganderair.png';

class GetMilesButton extends React.Component {
    render() {
        if (this.props.rewardsNum != "") {
            return ( 
                <div className={styles.continue} onClick={this.props.submit}>
                    <div id={styles.selectText} style={{color: "white"}}>Get Miles</div>
                </div>
            );
        }
        else {
            return (
                <div className={styles.continueDisabled}>
                    <div id={styles.selectText} style={{color: "#A7A7A7"}}>Get Miles</div>
                </div>
            );
        }
    }
}

class PaymentForm extends React.Component {
    render() {
        if (this.props.miles == "0") {
            return (
                <div id={styles.fileAnotherOuter} onClick={this.props.restartWorkflow} style={{top: "578px"}}>
                    <div id={styles.fileAnotherText}>Need help with something else?</div>
                </div>
            );
        }
        else if (this.props.paymentReceived) {
            return (
                <div>
                <div id={styles.resolutionOuter} style={{top: "596px"}}>
                    <div id={styles.checkOuter}>
                        <img src={checkmark} id={styles.check} />
                    </div>
                    <div id={styles.title}>{this.props.miles} Gander Points Issued</div>
                    <div id={styles.subtitle}>Your points will be available in your account ending in XX{this.props.rewardsNum.substring(this.props.rewardsNum.length - 4)} in 24 hours. If you do not receive your point, send an email to help@usegander.com with the below case ID.</div>
                    <div id={styles.caseNum}>Case ID: {this.props.hex}</div>
                </div>
                <div id={styles.fileAnotherOuter} onClick={this.props.restartWorkflow} style={{top: "1024px"}}>
                    <div id={styles.fileAnotherText}>Need help with something else?</div>
                </div>
                </div>
            );
        }
        else {
            return (
                <div id={styles.outerActive}>
                    <div id={styles.titleText}>Enjoy {this.props.miles} Miles on Us</div>
                    <div id={styles.approvedBox}>
                        <img src={ganderAir} id={styles.headerImgSw} />
                        <div id={styles.ganderLogoText}><span style={{fontWeight: "600"}}>Gander</span><br/>Awards</div>
                        <div id={styles.approvedTitle1} style={{color: "black", fontWeight: "600"}}>{this.props.miles}<br/>Points</div>
                    </div>
                    <div id={styles.otherOptionsTitle1}>Provide Payment Details</div>
                    <div className={styles.textInputOuter} style={{top: "160px"}}>
                        <input type="text" className={globalStyles.inputField} id="rapidRewards" placeholder='John-Doe' onInput={this.props.updateRewards} />
                        <div className={globalStyles.inputFieldTitle}>Gander Awards Account #</div>
                    </div>
                    <div id={styles.newAccountText}>Don't have an account? Join now: </div>
                    <div id={styles.backToSouthwest}>
                        <div id={styles.backToSouthwestText}>Back To ganderair.com</div>
                    </div>
                    <div id={styles.footerTitle}>Waiving Rights to Future Compensation for {this.props.flightsString}</div>
                    <div id={styles.footerSubtitle}>After clicking “Get Miles", you may not request further compensation for these flights from Gander Airlines and you waive your right to compensation under Department of Transportation Rules</div>
                    <GetMilesButton rewardsNum={this.props.rewardsNum} submit={this.props.receivePayment} />
                </div>
            )
        }
    }
}

class Resolution extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            rewardsNum: "",
            paymentReceived: false,
            genRanHex: [...Array(10)].map(() => Math.floor(Math.random() * 16).toString(16)).join('').toUpperCase()
        }
    }

    getMiles(category, loyalty) {
        let miles = 0;
        miles += FEEDBACK_CATEGORIES[category].comp;
        if (loyalty && FEEDBACK_CATEGORIES[category].goldEligible) miles += 5000;
        return miles;
    }

    getFeedbackResponse(feedbackCategory, loyalty, date) {
        if (feedbackCategory.escalation == FEEDBACK_RESPONSE.TIER0) {
            return `We are sorry you encountered ${feedbackCategory.issueText} during your flight on ${date}. We strive to deliver you a world-class experience and are disappointed when we don’t get it quite right. We will forward your feedback to ${feedbackCategory.department} and work hard to make sure other fliers don’t face similar issues. Thank you for your continued business.${loyalty ? " Given your loyalty to Gander, please accept a token of 5,000 miles so we can make up for the inconvenience." : ""}`;
        }
        else if (feedbackCategory.escalation == FEEDBACK_RESPONSE.TIER1) {
            return `We are sorry you encountered ${feedbackCategory.issueText} during your flight on ${date}. To make it right, we would like to deposit ${feedbackCategory.comp.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")} miles into your account. Fill out the prompts below to claim your miles. We have also forwarded your feedback to ${feedbackCategory.department} to make sure other fliers don’t face similar issues.${loyalty ? " Thank you for being a Gander Gold customer!" : ""}`;
        }
        else if (feedbackCategory.escalation == FEEDBACK_RESPONSE.TIER2) {
            return `We take ${feedbackCategory.issueText} very seriously and are escalating your feedback to ${feedbackCategory.department}. In the meantime, we are crediting your account with 10,000 miles. We take matters like this seriously and will get back to you as soon as possible.`;
        }
        else if (feedbackCategory.escalation == FEEDBACK_RESPONSE.REDIRECT_TO_GANDER) {
            return `We are sorry you are encountering issues with your bags. Please click “need help with something else” and then click “baggage” to get started with a baggage claim. You can also email bag services at bags@usegander.com if you need more help.`;
        }
        else if (feedbackCategory.escalation == FEEDBACK_RESPONSE.LIVE_SUPPORT) {
            return "We are so sorry to hear about your experience. It sounds like you would be better served by a live agent. Call us at 1-800-723-4000 to connect to a member of our team.";
        }
        else if (feedbackCategory.escalation == FEEDBACK_RESPONSE.OTHER) {
            return "We are sorry things did not go right during your journey with us. We forwarded your complaint to our customer experience department who look into how we can do better.";
        }
        else if (feedbackCategory.escalation == FEEDBACK_RESPONSE.POSITIVE) {
            return `We appreciate your positive feedback and will forward your compliment to the ${feedbackCategory.department} department. Thank you for flying with Gander Airways!`;
        }
    }

    render() {
        if (this.props.data.category == ISSUE_CATEGORIES.FEEDBACK && this.props.data.paymentDetails != "Compliment") {
            let flightsString = "";
            let flightsDate = "";
            for (let i = 0; i < this.props.data.flights.length; i++) {
                if (this.props.data.flights[i]) {
                    flightsString = this.props.baseData.flights[i].num;
                    flightsDate = this.props.baseData.flights[i].date;
                    break;
                }
            }
            let miles = seenFeedbackCategories.indexOf(this.props.data.paymentDetails) == -1 ? this.getMiles(this.props.data.paymentDetails, this.props.baseData.loyalty).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : "0";
            return (
                <div>
                    <div id={styles.resolutionOuter}>
                        <img src={heart} id={styles.heartImg} />
                        <div id={styles.title}>We Are Sorry</div>
                        <div id={styles.subtitle}>
                            {this.getFeedbackResponse(FEEDBACK_CATEGORIES[this.props.data.paymentDetails], this.props.baseData.loyalty, flightsDate)}
                        </div>
                    </div>
                    <PaymentForm miles={miles} paymentReceived={this.state.paymentReceived} 
                                 restartWorkflow={this.props.restartWorkflow} hex={this.state.genRanHex} 
                                 rewardsNum={this.state.rewardsNum} flightsString={flightsString} 
                                 updateRewards={(e) => this.setState({rewardsNum: e.target.value})} 
                                 receivePayment={() => this.setState({paymentReceived: true})} />
                    <div id={styles.whitespace2} />
                </div>
            );
        }
        let titleText = "";
        let subtitleText = "";
        if (this.props.data.paymentDetails == "Compliment") {
            titleText = "Thank you!";
            subtitleText = `We appreciate your positive feedback and will forward your compliment to the employee recognition department. Thank you for flying with Gander Airways!`;
        }
        else if (this.props.data.paymentForm == 0) {
            titleText = `$${this.props.data.paymentAmount} Gander Credit Issued`;
            subtitleText = `Your Gander voucher should be send to ${this.props.data.paymentDetails} within 24 hours. If you do not receive it, email help@airways.gander.com`;
        }
        else if (this.props.data.paymentForm == 1) {
            titleText = `${this.props.data.paymentAmount} Rewards Points Issued`;
            subtitleText = `Your points will be available in your account ending in ${this.props.data.paymentDetails} in 24 hours. If you do not receive your points, send an email to help@airways.gander.com with the below case ID.`;
        }
        else if (this.props.data.paymentForm == 2) {
            titleText = `$${this.props.data.paymentAmount} Issued via Electronic Funds Transfer`;
            subtitleText = `Your funds will be transferred to ${this.props.data.paymentDetails} within 24 hours. If you do not receive it, email help@airways.gander.com`;
        }
        else if (this.props.data.paymentForm == 3) {
            titleText = `$${this.props.data.paymentAmount} is on its way to @${this.props.data.paymentDetails}`;
            subtitleText = `You should receive a notification from Venmo in the next 10-15 minutes confirming receipt of your funds. If you don't receive your funds, email us at help@airways.gander.com.`;
        }
        if (this.props.data.category == ISSUE_CATEGORIES.LOST_BAG) {
            titleText = "Thank You!"
            subtitleText = "We are working on shipping your bag. Sorry for the inconvenience and we hope to get you reunited with your baggage soon.";
        }
        return (
            <div>
            <div id={styles.resolutionOuter}>
                <div id={styles.checkOuter}>
                    <img src={checkmark} id={styles.check} />
                </div>
                <div id={styles.title}>{titleText}</div>
                <div id={styles.subtitle} style={{top: `${this.props.data.paymentDetails == "Compliment" ? 285 : 265}px`}}>{subtitleText}</div>
                {
                    this.props.data.paymentDetails == "Compliment" ? 
                    <div />
                    :
                    <div id={styles.caseNum}>Case ID: {this.state.genRanHex}</div>
                }
                <div id={styles.whitespace} />
            </div>
            <div id={styles.fileAnotherOuter} onClick={this.props.restartWorkflow}>
                <div id={styles.fileAnotherText}>File Another Claim</div>
            </div>
            </div>
        );
    }
}

export default Resolution;