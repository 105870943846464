import React from 'react';
import styles from './Title.module.css';
import globalStyles from '../global.module.css';
import { ISSUE_CATEGORIES, MENU_STATES } from '../util';

import search from './images/search.svg';
import arrow from '../Form/images/arrow.svg';
import searchWhite from '../Form/images/searchWhite.svg';
import { DeletionAlert } from './Alerts';

class Title extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            popup: false,
            searchTerm: ""
        };
    }

    getTitle(issueCategory) {
        if (issueCategory == ISSUE_CATEGORIES.DISRUPTION_REIMBURSEMENT) {
            return "Disruption Reimbursement";
        }
        else if (issueCategory == ISSUE_CATEGORIES.PASSENGER_RIGHTS) {
            return "Passenger Rights Compensation";
        }
        else if (issueCategory == ISSUE_CATEGORIES.FEEDBACK) {
            return "Feedback";
        }
        else if (issueCategory == ISSUE_CATEGORIES.BAG_DAMAGE) {
            return "Baggage Claims"
        }
        else if (issueCategory == ISSUE_CATEGORIES.LOST_BAG) {
            return "Lost Baggage";
        }
        else {
            return "";
        }
    }

    getSubtitle(issueCategory) {
        if (issueCategory == ISSUE_CATEGORIES.DISRUPTION_REIMBURSEMENT) {
            return "Request reimbursement for meals, transportation, or hotels during a flight disruption within Gander Airways' control";
        }
        else if (issueCategory == ISSUE_CATEGORIES.PASSENGER_RIGHTS) {
            return "In certain jurisdictions, Gander Airways is able to provide additional compensation to comply with relevant passenger rights regulations";
        }
        else if (issueCategory == ISSUE_CATEGORIES.FEEDBACK) {
            return "Provide feedback to Gander Airways about your most recent flight experience. Please be as detailed as possible.";
        }
        else if (issueCategory == ISSUE_CATEGORIES.BAG_DAMAGE) {
            return "We are here to help you with lost, damaged, and delayed baggage. Submit a claim to get on your way.";
        }
        else if (issueCategory == ISSUE_CATEGORIES.LOST_BAG) {
            return "We are here to help you find your lost baggage. Please fill out the following form which will help us reunify you with your bags.";
        }
        else {
            return "";
        }
    }

    render() {
        if (this.props.state == MENU_STATES.ISSUE || this.props.state == MENU_STATES.LOADING) {
            return (
                <div id={styles.title_outer}>
                    <div id={styles.title_text}>How can we help?</div>
                    <div id={styles.search_outer}>
                        <img src={search} id={styles.search} />
                        <input type="text" id="issueCategory" className={globalStyles.inputField} style={{left: "50px", paddingLeft: "10px", right: "150px"}} placeholder="E.g My flight has been delayed" value={this.state.searchTerm} onChange={(e) => this.setState({searchTerm: e.target.value})} />
                        {
                            this.props.searchTerm == "" ? 
                            <div id={styles.submitSearchOuter} onClick={() => this.props.updateSearchTerm(this.state.searchTerm)}>
                                <div id={styles.submitSearchText}>Search</div>
                                <img src={searchWhite} id={styles.submitSearchImg} />
                            </div>
                            :
                            <div id={styles.submitSearchOuter} style={{textAlign: "center"}} onClick={() => {this.setState({searchTerm: ""}); this.props.updateSearchTerm("");}}>
                                <div id={styles.submitSearchText} style={{right: "12px"}}>Clear</div>
                            </div>
                        }
                    </div>
                </div>
            );
        }
        else if (this.props.state == MENU_STATES.DETAILS || this.props.state == MENU_STATES.CONFIRM) {
            return(
                <div>
                <div id={styles.title_outer}>
                    <div id={styles.title_text}>{this.getTitle(this.props.issueCategory)}</div>
                    <div id={styles.subtitle_text}>{this.getSubtitle(this.props.issueCategory)}</div>
                </div>
                <div id={styles.changeTrip} onClick={() => this.setState({popup: true})}>
                    <div id={styles.changeTripText}>Change Selection</div>
                    <img src={arrow} id={styles.changeTripImg} />
                </div>
                {
                    this.state.popup ? 
                    <DeletionAlert close={() => this.setState({popup: false})} delete={() => {this.setState({popup: false}); this.props.updateState(0);}} />
                    :
                    <div />
                }
                </div>
            );
        }
        else { // MENU_STATES.RESOLUTION
            return (<div />);
        }
    }
}

export default Title;